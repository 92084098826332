.state-gov-page {
  font-family: Arial, sans-serif;
}

/* State Facts Section */
.state-facts {
  background-color: #104410; /* Green background to match header */
  color: white; /* White text */
  padding: 15px; /* Reduced padding to tighten space */
  text-align: left; /* Left-align the text */
}

.state-facts h2 {
  font-size: 1.8em; /* Smaller font size */
  margin-bottom: 10px; /* Reduced margin */
  font-family: Arial, sans-serif; /* Updated to non-cursive font */
}

.state-facts ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.state-facts li {
  font-size: 1.2em; /* Smaller font size for list items */
  margin-bottom: 10px; /* Reduced space between items */
  padding-left: 20px; /* Space for custom bullet */
  position: relative;
}

.state-facts li::before {
  content: "•"; /* Custom bullet point */
  position: absolute;
  left: 0;
  font-size: 1.2em;
}

/* State Information Section */
.state-info {
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.state-image {
  flex: 1;
  background: url('State.png') no-repeat center center;
  background-size: cover;
  height: 120%;
  min-height: 450px;
}

.overlay-text-state {
  flex: 1;
  background-color: rgb(255, 255, 255);
  padding: 30px;
  box-sizing: border-box;
  margin-left: 20px;
}

.overlay-text-state h2 {
  font-size: 2.5em;
  color: #104410;
  margin-bottom: 20px;
}

.overlay-text-state ul {
  list-style: none;
  padding: 0;
}

.overlay-text-state li {
  margin-bottom: 15px;
  font-size: 1.8em;
}

.overlay-text-state a {
  color: #104410;
  text-decoration: underline;
}

.overlay-text-state a:hover {
  color: #ffcc00;
}

/* Responsive Design */
@media (max-width: 768px) {
  .state-info {
    flex-direction: column;
  }

  .state-image {
    display: none; /* Hide state image on mobile devices */
  }

  .overlay-text-state {
    margin-left: 0;
    margin-top: 20px;
  }

  .overlay-text-state h2 {
    font-size: 2em;
  }

  .overlay-text-state li {
    font-size: 1.5em;
  }
}
