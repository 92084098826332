/* Blog Container */
.blog-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 40px 20px;
    position: relative;
    box-sizing: border-box;
}

/* Blog Title */
.blog-title {
    font-size: 2.4em;
    color: #104410;
    font-family: Arial, sans-serif;
    margin-bottom: 20px;
    text-align: center;
}

/* Articles Container */
.blog-articles-hero {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px; /* Add gap for spacing between items */
    width: 100%;
    max-width: 600px; /* Restrict the width on mobile */
    justify-items: center;
}

/* Blog Article Card */
.blog-article-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    max-width: 500px;
}

.blog-article-card:hover {
    transform: scale(1.02);
}

.blog-article-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}

/* Article Content */
.blog-article-content h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #104410;
}

.blog-article-content p {
    font-size: 1em;
    color: #333;
    flex-grow: 1;
}

.blog-article-meta {
    font-size: 0.9em;
    color: #888;
    margin-bottom: 15px;
}

/* Load More Button */
.blog-load-more {
    background-color: #104410;
    color: white;
    padding: 15px 30px;
    font-size: 1.2em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
}

.blog-load-more:hover {
    background-color: #0d370d;
}

/* Desktop Adjustments */
@media (min-width: 768px) {
    .blog-title {
        font-size: 2.8em;
    }

    .blog-articles-hero {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 50px; /* Space between grid items */
        max-width: 1200px;
    }

    .blog-article-card {
        width: 100%;
    }

    .blog-article-image {
        height: 150px;
    }
}
