.builder-request-page {
    font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-builder {
    display: flex;
    height: 70vh;
    background-color: #104410;
}

.builder-image-left {
    flex: 1;
    background: url('Builder.png') no-repeat center center;
    background-size: cover;
}

.overlay-text-builder-right {
    flex: 1;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.overlay-text-builder-right h2 {
    font-size: 2.8em;
    font-family: Arial, sans-serif; /* Changed to standard Arial font */
    color: #104410;
    margin-bottom: 20px;
}

.overlay-text-builder-right p {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

/* Builder Form Section */
.builder-form-section {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.builder-form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2em;
    color: #104410;
    margin-bottom: 40px;
    font-weight: bold;
}

.builder-form-title h3 {
    margin-left: 15px;
}

.builder-form-title-icon {
    font-size: 1.5em;
    color: #104410;
}

.builder-form {
    width: 100%;
    max-width: 600px;
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif; /* Ensure consistent font family */
}

.builder-form input,
.builder-form select {
    width: 90%;
    padding: 18px;
    font-size: 1.3em;
    font-family: Arial, sans-serif; /* Standard font for inputs and selects */
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 25px;
}

.builder-form label {
    font-size: 1.3em;
    font-family: Arial, sans-serif; /* Standardized font for labels */
    margin-bottom: 10px;
}

.builder-form button {
    background-color: #104410;
    color: white;
    padding: 18px 40px;
    font-size: 1.3em;
    font-family: Arial, sans-serif; /* Standardized font for buttons */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 20px;
}

.builder-form button:hover {
    background-color: #0d370d;
}

/* Thank You Message */
.builder-thank-you-message {
    text-align: center;
    font-size: 2.2em;
    color: #104410;
    padding: 50px 20px;
}

.builder-thank-you-message h3 {
    font-size: 2.8em;
    color: #104410;
    margin-bottom: 15px;
    font-weight: bold;
}

.builder-thank-you-message p {
    font-size: 1.7em;
    color: #333;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-builder {
        flex-direction: column;
        height: auto;
    }

    .builder-image-left {
        height: 50vh;
    }

    .overlay-text-builder-right {
        padding: 20px;
    }

    .builder-form-title {
        font-size: 2.5em;
    }

    .builder-form-title-icon {
        font-size: 1.2em;
    }

    .builder-form {
        padding: 30px;
    }

    .builder-form input,
    .builder-form select {
        padding: 16px;
        font-size: 1.2em;
    }

    .builder-form button {
        padding: 16px 35px;
        font-size: 1.2em;
    }

    .builder-thank-you-message h3 {
        font-size: 2.4em;
    }

    .builder-thank-you-message p {
        font-size: 1.5em;
    }
}
