.cities-page {
  font-family: Arial, sans-serif;
}

.hero-cities {
  display: flex;
  position: relative;
  height: 80vh;
  background-color: #104410; /* Restored original green background */
  flex-direction: row-reverse;
}

.cities-image {
  flex: 1;
  background: url('Cities.png') no-repeat center center;
  background-size: cover;
  height: 100%;
}

.city-locator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.city-locator-container p {
  font-size: 1.5em;
  color: #104410;
  margin-right: 20px;
}

.city-locator-button {
  background-color: #104410;
  color: white;
  padding: 12px 28px;
  font-size: 1.3em;
  text-decoration: none;
  border: 2px solid #104410;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.city-locator-button:hover {
  background-color: white;
  color: #104410;
}

.overlay-text-cities {
  flex: 1;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.overlay-text-cities h2 {
  font-size: 2.5em;
  color: #104410;
  margin-bottom: 20px;
}

.overlay-text-cities p {
  font-size: 1.2em; /* Match the font size of county text */
  margin-bottom: 15px;
}

.overlay-text-cities ul {
  list-style: none;
  padding: 0;
}

.overlay-text-cities li {
  margin-bottom: 10px;
  font-size: 1.2em; /* Match the font size of county text */
}

/* Region Selector */
.region-selector-cities {
  text-align: center;
  margin: 50px 0;
}

.region-selector-cities button {
  background-color: white;
  color: #104410;
  border: 2px solid #104410;
  padding: 10px 25px;
  margin: 10px;
  font-size: 1.3em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.region-selector-cities button:hover {
  background-color: #104410;
  color: white;
}

/* Section for cities */
.city-section {
  margin: 50px 0;
  padding: 0 30px; /* Add padding for the city sections */
}

.city-title {
  font-size: 2.5em;
  color: #104410;
  text-align: center;
  margin-bottom: 40px;
}

.city-item {
  padding: 0 20px;
  margin-bottom: 25px;
}

.city-item h3 {
  color: rgb(19, 56, 17);
  font-size: 1.8em;
  margin-bottom: 15px;
}

.city-item p {
  margin: 0 0 10px 0;
  font-size: 1.8em; /* Match the font size of county text */
}

.city-item ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.city-item li {
  font-size: 1.8em; /* Match the font size of county text */
  margin-bottom: 10px;
}

.city-item li::before {
  content: ''; /* Removes the bullet point */
}

/* Coming Soon */
.coming-soon {
  text-align: center;
  font-size: 2em;
  color: #ffcc00;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-cities {
    flex-direction: column;
    height: auto;
  }

  .cities-image {
    height: 50vh;
  }

  .overlay-text-cities {
    padding: 20px;
  }

  .city-title {
    font-size: 2em;
  }

  .city-item {
    padding: 0 15px;
  }

  .city-item h3 {
    font-size: 1.5em;
  }

  .city-item p {
    font-size: 1.2em;
  }

  .city-item li {
    font-size: 1.2em;
  }

  .city-locator-container {
    flex-direction: column;
  }

  .city-locator-container p {
    margin: 0 0 15px 0;
  }

  .city-locator-button {
    width: auto;  /* Adjust width to auto */
    max-width: 90%; /* Add some buffer on the sides */
    text-align: center;
    margin: 0 auto; /* Center the button with padding on the sides */
  }
}
