.counties-page {
  font-family: Arial, sans-serif;
}

.hero-counties {
  display: flex;
  position: relative;
  height: 80vh;
  background-color: #104410; /* Restored original green background */
}

.counties-image {
  flex: 1;
  background: url('Counties.png') no-repeat center center;
  background-size: cover;
  height: 100%;
}

.overlay-text {
  flex: 1;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.overlay-text h2 {
  font-size: 2.5em;
  color: #104410;
  margin-bottom: 15px;
}

.overlay-text ul {
  list-style: none;
  padding: 0;
}

.overlay-text li {
  margin-bottom: 10px;
  font-size: 1.8em;
}


/* Region Selector */
.region-selector {
  text-align: center;
  margin: 50px 0;
}

.region-selector button {
  background-color: white; /* Changed button background to white */
  color: #104410; /* Green text to contrast with white background */
  border: 2px solid #104410; /* Green border for styling */
  padding: 10px 25px;
  margin: 5px;
  font-size: 1.3em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.region-selector button:hover {
  background-color: #104410; /* Green background on hover */
  color: white; /* White text on hover */
}

/* Section for counties */
.county-section {
  margin: 50px 0;
  padding: 0 20px; /* Add padding to the left and right of the county sections */
}

.county-title {
  font-size: 2.5em;
  color: #104410;
  text-align: center;
  margin-bottom: 40px;
}

.county-links {
  background-color: #104410;
  color: white;
  padding: 40px 20px;
}

.county-item {
  padding: 0 20px; /* Indent content within each county item */
  margin-bottom: 20px; /* Add space between county items */
}

.county-item h3 {
  color: rgb(19, 56, 17);
  font-size: 1.8em;
  margin-bottom: 15px;
}

.county-item p {
  margin: 0 0 10px 0;
  font-size: 1.8em;
}

.coming-soon {
  text-align: center;
  font-size: 2em;
  color: #ffcc00;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-counties {
    flex-direction: column;
    height: auto;
  }

  .counties-image {
    height: 50vh; /* Adjusted height for smaller screens */
  }

  .overlay-text {
    padding: 20px;
  }

  .county-title {
    font-size: 2em;
  }

  .county-item {
    padding: 0 15px; /* Adjust padding for smaller screens */
  }

  .county-item h3 {
    font-size: 1.5em;
  }

  .county-item p {
    font-size: 1em;
  }
}
