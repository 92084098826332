/* Article.css */
.article-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
}

.article-page h1 {
    font-size: 2.2em;
    margin-bottom: 10px;
    color: #104410;
}

.article-meta {
    font-size: 0.9em;
    color: #888;
    margin-bottom: 20px;
}

.article-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
}

.article-page p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
}
