.footer {
  background-color: rgb(16, 68, 16); /* Same green as header */
  color: white;
  padding: 10px;
  text-align: center;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.footer-links li {
  font-size: 18px;
  font-weight: bold;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;  /* Smooth transition for hover effect */
}

.footer-links a:hover {
  color: #ffcc00; /* Change the color to yellow on hover */
}

.info-text {
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
}

.footer-copyright {
  margin-top: 10px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 5px; /* Reduce padding on the footer */
  }

  .footer-links {
    display: none; /* Hide the footer links on screens smaller than 768px */
  }

  .info-text {
    padding: 5px 10px; /* Reduce padding inside the text area */
    margin-top: 5px;   /* Reduce top margin to tighten space */
    font-size: 0.9em;  /* Optional: slightly reduce font size for better fit */
  }

  .footer-copyright {
    margin-top: 5px; /* Reduce space between copyright and text */
  }
}
