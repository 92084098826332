.App {
  font-family: Arial, sans-serif;
  height: 100vh; /* Full height of the viewport */
  display: flex;
  flex-direction: column;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
