.realtor-request-page {
    font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-realtor {
    display: flex;
    height: 80vh;
    background-color: #104410;
}

.realtor-image-left {
    flex: 1;
    background: url('Realtor.png') no-repeat center center;
    background-size: cover;
}

.overlay-text-realtor-right {
    flex: 1;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.overlay-text-realtor-right h2 {
    font-size: 2.8em;
    font-family: Arial, sans-serif;
    color: #104410;
    margin-bottom: 20px;
}

.overlay-text-realtor-right p {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

/* Realtor Form Section */
.realtor-form-section {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.realtor-form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2em;
    color: #104410;
    margin-bottom: 40px;
    font-weight: bold;
}

.realtor-form-title h3 {
    margin-left: 15px;
}

.realtor-form-title-icon {
    font-size: 1.5em;
    color: #104410;
}

.realtor-form {
    width: 100%;
    max-width: 600px;
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
}

.realtor-form input,
.realtor-form select {
    width: 90%;
    padding: 18px;
    font-size: 1.3em;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 25px;
}

.realtor-form label {
    font-size: 1.3em;
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
}

.realtor-form button {
    background-color: #104410;
    color: white;
    padding: 18px 40px;
    font-size: 1.3em;
    font-family: Arial, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 20px;
}

.realtor-form button:hover {
    background-color: #0d370d;
}

/* Thank You Message */
.realtor-thank-you-message {
    text-align: center;
    font-size: 2.2em;
    color: #104410;
    padding: 50px 20px;
}

.realtor-thank-you-message h3 {
    font-size: 2.8em;
    color: #104410;
    margin-bottom: 15px;
    font-weight: bold;
}

.realtor-thank-you-message p {
    font-size: 1.7em;
    color: #333;
    margin-top: 20px;
}

/* Featured Realtors Section */
.realtor-form-featured {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.realtor-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.realtor-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.realtor-card-title {
    display: flex;
    align-items: center;
    font-size: 1.8em;
    color: #104410;
    margin-bottom: 15px;
}

.realtor-card-icon {
    font-size: 1.5em;
    color: #104410;
    margin-right: 10px;
}

.realtor-card-content {
    font-size: 1.2em;
    color: #333;
    text-align: center;
}

.realtor-card-content p {
    margin: 10px 0;
}

.realtor-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.realtor-card-list li {
    background-color: #eff5ee;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1em;
    color: #333;
}

/* Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.realtor-card {
    animation: fadeIn 0.6s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-realtor {
        flex-direction: column;
        height: auto;
    }

    .realtor-image-left {
        height: 50vh;
    }

    .overlay-text-realtor-right {
        padding: 20px;
    }

    .realtor-form-title {
        font-size: 2.5em;
    }

    .realtor-form-title-icon {
        font-size: 1.2em;
    }

    .realtor-form {
        padding: 30px;
    }

    .realtor-form input,
    .realtor-form select {
        padding: 16px;
        font-size: 1.2em;
    }

    .realtor-form button {
        padding: 16px 35px;
        font-size: 1.2em;
    }

    .realtor-thank-you-message h3 {
        font-size: 2.4em;
    }

    .realtor-thank-you-message p {
        font-size: 1.5em;
    }

    .realtor-form-featured {
        grid-template-columns: 1fr;
    }
}
