.feedback-page {
    font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-feedback {
    display: flex;
    height: 50vh;
    background-color: #104410; /* Green background */
}

.overlay-text-left {
    flex: 1;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}

.feedback-image-right {
    flex: 1;
    background: url('Feedback.png') no-repeat center center;
    background-size: cover;
}

.overlay-text-left h2 {
    font-size: 2.5em;
    color: #104410;
}

.overlay-text-left p {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

/* Feedback Form Section */
.feedback-form-section {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.feedback-form {
    width: 100%;
    max-width: 600px;
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif; /* Ensure consistent font */
}

.feedback-form textarea,
.feedback-form input[type="email"] {
    width: 100%;
    padding: 18px;
    font-size: 1.3em;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 25px;
    resize: none;
    font-family: Arial, sans-serif; /* Standardized font */
}

/* Adjust input height for email */
.feedback-form input[type="email"] {
    height: auto;
}

.feedback-form button {
    background-color: #104410;
    color: white;
    padding: 18px 40px;
    font-size: 1.3em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
    font-family: Arial, sans-serif; /* Standardized font for button */
}

.feedback-form button:hover {
    background-color: #0d370d;
}

/* Thank You Message */
.thank-you-message {
    text-align: center;
}

.thank-you-message h3 {
    font-size: 2.5em;
    color: #104410;
}

.thank-you-message p {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-feedback {
        flex-direction: column;
        height: auto;
    }

    .feedback-image-right {
        height: 50vh;
    }

    .overlay-text-left {
        padding: 20px;
    }

    .feedback-form {
        padding: 30px;
    }

    .feedback-form textarea,
    .feedback-form input[type="email"] {
        padding: 16px;
        font-size: 1.2em;
    }

    .feedback-form button {
        padding: 16px 35px;
        font-size: 1.2em;
    }

    .thank-you-message h3 {
        font-size: 2.4em;
    }

    .thank-you-message p {
        font-size: 1.5em;
    }
}
