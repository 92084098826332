.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 20; /* Ensure it overlays any other content */
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.hamburger-menu .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger-menu .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.bm-item-list {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Adjust this to align properly below the header */
  right: 20px;
  background-color: rgb(16, 68, 16); /* Match the header background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  z-index: 20; /* Ensure it overlays any other content */
}

.bm-item-list.open {
  display: flex;
}

.bm-item-list a {
  color: white;
  text-decoration: none;
  margin-bottom: 15px; /* Increased margin for better spacing */
  font-size: 1.5em; /* Increased font size */
  font-weight: bold; /* Made the font bold */
  transition: color 0.3s;
}

.bm-item-list a:hover {
  color: #ffcc00;
}

@media (max-width: 768px) {
  .bm-item-list {
    width: 250px; /* Adjust width for mobile view */
  }
}
