.header {
  background-color: rgb(16, 68, 16);
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px; /* Consistent height for the header across all pages */
  box-sizing: border-box; /* Ensure padding is included in height */
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.desktop-menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.desktop-menu li {
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
}

/* Style for the links inside desktop-menu */
.desktop-menu a {
  color: white; /* Match the original text color */
  text-decoration: none; /* Remove the underline */
  cursor: pointer;
}

.desktop-menu a:hover {
  color: #ffcc00; /* Optional hover effect */
}

.active-link {
  color: #ffcc00; /* Change the color of the active link */
  font-weight: bold;
}

/* Mobile menu styles */
.mobile-menu {
  display: none;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .bm-burger-button {
    display: block;
    width: 36px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
