.hero {
  position: relative;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
}

.image-bg {
  background: url('/src/components/EastTN.png') no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-text {
  text-align: center;
  font-size: 24px;
  color: black;
  font-weight: bold;
  margin-top: 30px; /* Adjust this value to change spacing under the header */
}

.hero-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgb(16, 68, 16);
}

.hero-links {
  width: 100%;
  padding: 10px 0;
}

.hero-links ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.hero-links li {
  color: white;
  cursor: pointer;
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
}

.info-text {
  color: white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

/* Adjust hero height for mobile to 90vh */
@media (max-width: 768px) {
  .hero {
    height: 90vh; /* Set hero height to 90vh on mobile */
  }

  .hero-links {
    display: none; /* Hide hero links on mobile */
  }
}
